import React from "react";

import TextField from "@material-ui/core/TextField";

const TextArea = () => {
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const textFieldStyle = {
    // marginTop: 8,
    width: "100%",
    height: "60vh",
  };

  const inputStyle = {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "start",
  };

  const props = {
    style: textFieldStyle,
    label: "Notes",
    // multiline: true,
    variant: "outlined",
    rows: containerStyle.height / 1,
    InputProps: { style: inputStyle },
    height: "100%",
  };

  return (
    <div style={containerStyle}>
      <p>Private session notes, only visible to you.</p>
      <TextField {...props} />
    </div>
  );
};
export default TextArea;
