
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import clsx from "clsx";


import PannelNav from '../views/Host/ControlsView/ControlTabs'
import PannelControls from '../views/Host/ControlsView/PannelControls'
import TimerControls from '../views/Host/ControlsView/TimerControls'

import styles from "../assests/jss/material-dashboard-pro-react/layouts/hostStyle";

export default function SpacingGrid() {
  const wrapper = React.createRef();
  const useStyles = makeStyles(styles);

  const classes = useStyles();

  return (
        <div className={clsx(classes.controlPannelBackground)} ref={wrapper} id="main">
          <div className={clsx(classes.controlPannel)}>
             <PannelNav />
          </div>  
          <div className={clsx(classes.controlPannelTimer)}>
             <TimerControls />
          </div>
          <div className={clsx(classes.controlPannelFooter)}>
             <PannelControls />
          </div>
        </div>
  );
}