import React from "react";
import HostView from "../views/Host/Host";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components

import styles from "../assests/jss/material-dashboard-pro-react/layouts/hostStyle";

const useStyles = makeStyles(styles);

export const HostLayoutContext = React.createContext({});

export default function Pages(props) {
  // const { ...rest } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });

  return (
    <div>
        <div className={classes.innerPage} ref={wrapper}>
          <HostLayoutContext.Provider value={{}}>
            <HostView />
          </HostLayoutContext.Provider>
        </div>
    </div>
  );
}
