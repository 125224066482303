/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import backImage from "../../assests/img/backButton.png";
import aheadImage from "../../assests/img/aheadButton.png";
import "./NavButtons.css";

const useStyles = makeStyles({
  buttons: {
    color: "#b7b7b7",
    zIndex: "1",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    height: "32px",
    padding: "0",
  },
  imageAhead: {
    width: "35px",
  },
  imageBack: {
    width: "35px",
  },
});
const channel = new BroadcastChannel("playerControls");

const VideoControls = ({ newSlide, setNewSlide }) => {
  const classes = useStyles();
  const [buttonText, setButtonText] = useState(
    localStorage.getItem("videoPlaying") === "true" ? "PAUSE" : "START"
  );

  const playFunction = () => {
    if (buttonText === "START") {
      setButtonText("PAUSE");
      localStorage.setItem("videoPlaying", true);
      channel.postMessage("PLAY");
    } else {
      setButtonText("START");
      localStorage.setItem("videoPlaying", false);
      channel.postMessage("PAUSE");
    }
  };

  const backFunction = () => {
    channel.postMessage("back10");
  };

  const forwardFunction = () => {
    channel.postMessage("forward10");
  };

  useEffect(() => {
    if (newSlide) {
      setButtonText("START");
      setNewSlide(false);
    }
  }, [newSlide]);

  const BootstrapButton = withStyles({
    root: {
      boxShadow: "none",
      textTransform: "none",
      backgroundColor: "#214D49",
      fontSize: ".80rem",
      color: "white",
      width: "125px",
      "&:hover": {
        backgroundColor: "#214d4994",
        boxShadow: "none",
      },
      "&:active": {
        boxShadow: "none",
        backgroundColor: "#214D49",
      },
      "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
      },
    },
  })(Button);

  return (
    <div className="root">
      <div
        style={{
          marginBottom: "5px",
          textAlign: "center",
          lineHeight: "10px",
          marginRight: "10px",
          marginTop: "13px",
        }}
      >
        <p style={{ fontSize: "9px" }}>SCENE CONTROLS</p>
      </div>
      <span className={classes.buttons}>
        <Button onClick={() => backFunction()} id="skipBackdBtn">
          <img src={backImage} className={classes.imageBack} alt="back" />
        </Button>

        <BootstrapButton
          variant="contained"
          onClick={() => playFunction()}
          // style={'btn'}
          id="playBtn"
        >
          {buttonText}
        </BootstrapButton>

        <Button onClick={() => forwardFunction()} id="skipForwardBtn">
          <img src={aheadImage} className={classes.imageAhead} alt="ahead" />
        </Button>
      </span>
    </div>
  );
};

VideoControls.propTypes = {
  newSlide: PropTypes.bool,
  setNewSlide: PropTypes.func,
};

export default VideoControls;
