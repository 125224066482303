export class Time {
    static getTimeFromSeconds(secs) {
      const totalSeconds = Math.ceil(secs);
      const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
      const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
      const seconds = Math.floor(totalSeconds % 60);

      const formatedHours = hours <10? `0${hours}`: hours;
      const formatedMinutes = minutes <10? `0${minutes}`: minutes;
      const formatedSeconds = seconds <10? `0${seconds}`: seconds;
      
      return {
        seconds: formatedSeconds,
        minutes: formatedMinutes,
        hours: formatedHours,
      };
    }
  
    static getSecondsFromPrevTime(prevTime) {
      const now = new Date().getTime();
      const milliSecondsDistance = now - prevTime;
      if (milliSecondsDistance > 0) {
        const val = milliSecondsDistance / 1000;
        return Math.round(val);
      }
      return 0;
    }
}