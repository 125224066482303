import React, { useEffect, useState, useRef } from "react";

import "./mystyle.scss";

const Messages = [
  {
    message:
      "Made me feel Inadequate about the wrong responses, but made me stronger as well!",
    userId: 21,
  },
  {
    message: "i think sometimes we forget these things about ourselves",
    userId: 22,
  },
  { message: "Flaws are beautiful", userId: 17 },
  {
    message: "DUDE i am an only-onsite worker. commuting is no fun",
    userId: 41,
  },
  {
    message:
      "I feel connected when I can take off my “mask” of being a perfect mom, wife, friend. It makes me feel connected when I can admit to others that I am not perfect, my flaws are part of who I am, and let them accept me as I am.",
    userId: 11,
  },
];

export default function CenteredGrid(props) {
  const { setMessageCount } = props;
  const [messages, setMessages] = useState(Messages);
  const chatRef = useRef(null);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    setMessageCount(messages.length);
  }, []);

  //   useEffect(() => {
  //       console.log(chatRef.current)
  //       setMessageCount(0)

  //   }, [chatRef.current]);

  return (
    <div className={"wrapper"} ref={chatRef}>
      <div className={"inner"}>
        {Messages.map((msg) => (
          <div className={"message-wrapper them"} key={msg.message}>
            <div className="circle-wrapper">{msg.userId}</div>
            <div className={"text-wrapper"}>{msg.message}</div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
}
