import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import VideoControls from "./VideoControls";
// import { TRUE } from "sass";
import Cookie from "universal-cookie";

const cookie = new Cookie();

const controlChannel = new BroadcastChannel("slideControls");
const buttonChannel = new BroadcastChannel("buttonText");

const useStyles = makeStyles({
  root: {
    height: "100%",
    color: "#b7b7b7",
    zIndex: "1",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
  },
  button: {
    color: "#214D49",
    fontSize: "1.1rem",
    height: "35px",
    lineHeight: "0px",
  },
  videoControls: {
    width: "55%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignContent: "center",
  },
  navigation: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "45%",
  },
});

function SlideNavButtons() {
  const classes = useStyles();
  const [newSlide, setNewSlide] = useState(false);
  const [nextButtonText, setNextButtonText] = useState(
    localStorage.getItem("rightButtonText")
      ? localStorage.getItem("rightButtonText")
      : "Next"
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [pin] = useState(localStorage.getItem("sessionPin"));

  const nextSlide = () => {
    localStorage.setItem("videoPlaying", false);
    setNewSlide(true);
    controlChannel.postMessage("next");
  };
  const backSlide = () => {
    localStorage.setItem("videoPlaying", false);
    setNewSlide(true);
    controlChannel.postMessage("back");
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const endSession = () => {
    controlChannel.postMessage("endSession");
    window.close()
  };

  useEffect(() => {
    return () => {
      controlChannel.close();
    };
  }, []);

  useEffect(() => {
    // console.log("use new", newSlide);
    if (newSlide) {
      const d = new Date();
      d.setTime(d.getTime() + 4 * 24 * 60 * 60 * 1000);
      cookie.set("time", 0, {
        expires: d,
        domain: process.env.REACT_APP_DOMAIN,
        path: "/",
      });
    }
  }, [newSlide]);

  useEffect(() => {
    buttonChannel.onmessage = (ev) => {
      switch (ev.data) {
        case "back":
          setNextButtonText("Next");
          break;
        case "lastSlide":
          setNextButtonText("End");
          break;
        case "open":
          setIsDialogOpen(true);
          break;
        default:
          setNextButtonText("Next");
      }
    };
  }, []);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.videoControls}>
          <VideoControls newSlide={newSlide} setNewSlide={setNewSlide} />
        </div>

        <div className={classes.navigation}>
          <div
            style={{
              marginBottom: "-10px",
              // height: "10px",
            }}
          >
            <p
              style={{
                fontSize: "9px",
              }}
            >
              SLIDE&nbsp;CONTROLS
            </p>
          </div>
          <span id="slideButton">
            <Button
              className={classes.button}
              id="slideButton"
              onClick={() => backSlide()}
            >
              &#8249;&nbsp;Back
            </Button>
            |
            <Button
              className={classes.button}
              id="slideButton"
              onClick={() => nextSlide()}
              // rightButtonText={rightButtonText}
            >
              {nextButtonText}&nbsp;&#8250;
            </Button>
          </span>
        </div>
      </div>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ color: "#f50057" }}>WARNING!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ending this session will shut down all activities for participants
            who are logged in with this PIN. Are you sure you would like to end
            session {pin} now?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={endSession} color="secondary" size="small">
            End
          </Button>
          |
          <Button
            onClick={handleCloseDialog}
            color="inherit"
            autoFocus
            size="large"
            fullWidth
            outlined
          >
            Never mind, I'll give participants more time for the last activity
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

SlideNavButtons.propTypes = {
  // channel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default SlideNavButtons;
