import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Cookie from "universal-cookie";

const cookie = new Cookie();
const scriptsChannel = new BroadcastChannel("script");

const useStyles = makeStyles((theme) => ({
  //   root: {
  //     flexGrow: 1,
  //     padding: 10
  //   },

  script: {
    textAlign: "left",
    color: theme.palette.text.secondary,
    overflow: "hidden",
  },
}));

export default function CenteredGrid(props) {
  const classes = useStyles();
  // const [durationMin, setDurationMin] = useState(0)
  const [modScript, setModScript] = useState("Not Available");

  useEffect(() => {
    scriptsChannel.onmessage = (ev) => {
      //   setDurationMin(ev.data.durationMin? ev.data.durationMin: 0)
      setModScript(ev.data.script ? ev.data.script : "");
    };
  }, []);

  useEffect(() => {
    // const foundScript = cookie.get("script");
    const localScript = localStorage.getItem("slideScript");

    // setDurationMin(foundScript.durationMin? foundScript.durationMin: 0)
    setModScript(localScript ? localScript : "");
  }, []);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: modScript }}
      className={classes.script}
    ></div>
  );
}
