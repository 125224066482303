import { useState, useRef,useEffect } from 'react';
import { Time } from '../../utils/Time';
// import { useInterval } from './hooks';
import Cookie from "universal-cookie";

const cookie = new Cookie();

const channel = new BroadcastChannel("slideControls");


function useInterval(callback, delay) {
    const callbacRef = useRef();
  
    // update callback function with current render callback that has access to latest props and state
    useEffect(() => {
      callbacRef.current = callback;
    });
  
    useEffect(() => {
      if (!delay) {
        return () => {};
      }
  
      const interval = setInterval(() => {
        callbacRef.current && callbacRef.current();
      }, delay);
      return () => clearInterval(interval);
    }, [delay]);

  }
  
  
function useStopwatch() {
    const [passedSeconds,setPassedSeconds ] = useState(0);
    const [time,setTime] = useState(new Date());
    const [seconds, setSeconds] = useState(passedSeconds + Time.getSecondsFromPrevTime(time || 0, true));

    useEffect(() => {
// resets the timer data when a slide changes
      channel.onmessage = ev => {
        setTime(new Date())
        setPassedSeconds(0)
      };
      setPassedSeconds( parseInt(cookie.get("time")? parseInt(cookie.get("time"), 'parse') : 0 ));

      return () => {
        channel.close();
      };
    }, []);

  
    useInterval(() => {
      setSeconds(passedSeconds + Time.getSecondsFromPrevTime(time, true));

      const d = new Date();
      d.setTime(d.getTime() + (4 * 24 * 60 * 60 * 1000));
//  this sets the sec in cookies so if user refreshes or reloads the control 
//   pannel it will start at the last time the app was counting

//this could be better if we use the actual time not just the sec. then calculated from the refresh time.
    if(Number(seconds)){
      cookie.set('time', seconds, { expires: d, domain: process.env.REACT_APP_DOMAIN, path: '/' });

    }
   
    }, 1000);
  

    return {
      ...Time.getTimeFromSeconds(seconds),
    };
  }


export default function Timer() {
  const {
    seconds,
    minutes,
    hours,
  } = useStopwatch();

    return (
        <div>
          <span>{minutes}</span>:<span>{seconds}</span>
        </div>

    );
  }