import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  videoPlayer: {
    position: 'fixed',
    bottom: '10px',
    maxWidth: '85%',
    maxHeight: '85%',
  }
});
const channel = new BroadcastChannel("playerControls");

const playFunction = () => {
  var video = document.getElementById("myVideo");

    video.play();
    video.style.display = "block";
};

const pauseFunction = () => {
  var video = document.getElementById("myVideo");
    video.pause();
};


const backFunction = () => {
  var video = document.getElementById("myVideo");

video.currentTime = video.currentTime - 10
};

const forwardFunction = () => {
  var video = document.getElementById("myVideo");

video.currentTime = video.currentTime + 10
};

const VideoSlide = ({ videoUrl, title }) => {
  const classes = useStyles();
  const videoRef = useRef();

  useEffect(() => {    
    videoRef.current?.load();
  }, [videoUrl]);

  useEffect(() => {
    channel.onmessage = ev => {
      switch(ev.data) {
        case 'play':
          return playFunction();
        case 'pause':
          return pauseFunction();    
        case 'back10':
          return backFunction(); 
        case 'forward10':
          return forwardFunction();
        default:
          return null;
      }
  
    };

    // return () => {
    //   channel.close();
    // };
  }, []);


  return (
    <>
      <video id="myVideo" ref={videoRef} className={classes.videoPlayer}>
          <source src={videoUrl} type="video/mp4" />
        </video>
    </>
  );
};

VideoSlide.propTypes = {
  videoUrl: PropTypes.string,
  title: PropTypes.string,
};

export default VideoSlide;
