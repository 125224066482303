import React, { useState } from "react";
import PropTypes from "prop-types";
// import sampleAnswers from "./sampleAnswers";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import IconButton from "@material-ui/core/IconButton";
// import Button from "../../../../components/CustomButtons/Button";
import Bar from "./Bar";
import Doughnut from "./Doughnut";

const useStyles = makeStyles({
  outerDiv: {
    // display: "flex",
    // marginTop: "35px",
    // maxWidth: "100vw",
  },
  labelOuterDiv: {
    // display: "flex",
    // justifyContent: "center",
    // flexDirection: "column",
    margin: "125px 0px 0px 0px",
  },
  individualLabel: {
    display: "flex",
    alignItems: "center",
    width: "450px",
  },
  colorKey: {
    minWidth: "25px",
    height: "20px",
  },
  text: {
    fontSize: "20px",
    marginLeft: "12px",
    color: "#666666",
  },
  buttonDiv: {
    // display: "flex",
    // justifyContent: "center",
    // // alignItems: "flex-end",
    // marginBottom: "90px",
    // // position: "absolute",
    // margin: "75px 1px",
    // color: "#b7b7b7",
  },
  button: {
    // color: "#b7b7b7",
  },
  title: {
    color: "#2A7050",
    fontSize: "xx-large",
    marginTop: "0px",
    marginLeft: "-454px",
  },
  navResults: {
    display: "flex",
    justifyContent: "center",
    margin: "75px 1px",
  },
});

const PollResults = (props) => {
  const { sessionData } = props;
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const data = sessionData.data.results;
  // console.log("data", sessionData);

  const classes = useStyles();

  let myLabels = [];
  let myColors = [
    "#0EB79D",
    "#AE3C82",
    "#AACE8C",
    "#14658B",
    "#DA9E89",
    "#4AB0AD",
    "#FFAD18",
    "#E4C2B2",
    "#42A231",
    "#C4580B",
  ];

  if (sessionData.data.results) {
    sessionData.data.results[currentQuestion].answers.map((x) =>
      myLabels.push(Object.keys(x)[0])
    );
  }

  const handleClickRight = () => {
    if (data.length > currentQuestion + 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setCurrentQuestion(0);
    }
  };

  const handleClickLeft = () => {
    if (data.length > currentQuestion + 1 && currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    } else if (currentQuestion === 0) {
      setCurrentQuestion(data.length - 1);
    } else if (data.length === currentQuestion + 1) {
      setCurrentQuestion(data.length - 2);
    }
  };

  return (
    <>
      {data ? (
        <Grid container direction="column" alignItems="center">
          {/* <Grid container item direction="row"> */}
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            // alignItems="center"
            className={classes.outerGrid}
          >
            <Grid item className={classes.labelOuterDiv}>
              {myLabels.map((label, index) => {
                return (
                  <Grid className={classes.individualLabel} key={index}>
                    <div
                      className={classes.colorKey}
                      style={{ backgroundColor: myColors[index] }}
                    />

                    <p className={classes.text}>{label}</p>
                  </Grid>
                );
              })}
            </Grid>

            {data.map((x, index) => {
              if (x.chartType === "bar") {
                return (
                  <Grid
                    item
                    key={x.id}
                    style={{
                      display: currentQuestion === index ? " " : "none",
                      width: "40vw",
                      maxHeight: "45vh",
                      // height: "40vh",
                    }}
                  >
                    <h3 className={classes.title}>{x.question}</h3>
                    <Bar answers={x.answers} />
                  </Grid>
                );
              }
              if (x.chartType === "doughnut") {
                return (
                  <Grid
                    item
                    key={x.id}
                    style={{
                      display: currentQuestion === index ? " " : "none",
                      width: "40vw",
                      height: "40vh",
                    }}
                  >
                    <Doughnut answers={x.answers} />
                  </Grid>
                );
              }
            })}
          </Grid>
          <Grid
            item
            // align-self="center"
            className={classes.navResults}
          >
            <IconButton
              className={classes.button}
              onClick={() => handleClickLeft()}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <span
              style={{
                display: "grid",
                alignItems: "center",
                color: "#666666",
              }}
            >
              {`Rapid Poll ${currentQuestion + 1} of ${data.length} `}
            </span>
            <IconButton
              className={classes.button}
              onClick={() => handleClickRight()}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Grid>
        </Grid>
      ) : (
        <div />
      )}
    </>
  );
};

PollResults.propTypes = {
  sessionData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.object])
  ),
};

export default PollResults;
