import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Timer from "../../../components/NavButtons/TimeClock";

import Cookie from "universal-cookie";

const cookie = new Cookie();
const scriptsChannel = new BroadcastChannel("script");

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    // padding: 10,
    backgroundColor: "white",
    borderRadius: "10px",
  },
}));

export default function CenteredGrid(props) {
  const classes = useStyles();
  const [durationMin, setDurationMin] = useState(0);

  useEffect(() => {
    scriptsChannel.onmessage = (ev) => {
      setDurationMin(ev.data.durationMin ? ev.data.durationMin : 0);
    };
  }, []);

  useEffect(() => {
    // const foundSctipt = cookie.get("script");
    const localDuration = localStorage.getItem("slideDuration");
    setDurationMin(localDuration ? localDuration : 0);
  }, []);

  return (
    <div className={classes.root}>
      <Timer
        durationMin={durationMin}
        style={{ backgroundColor: "white", borderRadius: "10px" }}
      />
    </div>
  );
}
