/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import { AuthContext } from "../../Auth";
import Stats from "./Stats";
import Modules from "./ModuleScreens";
import Pin from "./Pin";
import { loadAuthToken } from "../../utils/auth";
import Cookie from "universal-cookie";

const cookie = new Cookie();
//this is to control the slides from side pannel
const controlsChannel = new BroadcastChannel("slideControls");
const scriptsChannel = new BroadcastChannel("script");
const buttonChannel = new BroadcastChannel("buttonText");

const Host = () => {
  // const [loginLoading, setLoginLoading] = useState(false);
  const [pin, setPin] = useState(0);
  const [audienceUsers, setAudienceUsers] = useState(0);
  const [liveQuestions, setLiveQuestions] = useState(0);
  const [modules, setModules] = useState([]);
  const [currentSlide, setCurrentSlide] = useState({});
  const [index, setIndex] = useState(Number(cookie.get("slideIndex")));
  const [rightButtonText, setRightButtonText] = useState("Next");
  const [currentModule, setCurrentModule] = useState({});
  const [sessionId, setSessionId] = useState(0);
  const [isDemo, setIsDemo] = useState(false);
  const [channel, setChannel] = useState("");
  const [flagshipModule, setFlagshipModule] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { user, pusher } = useContext(AuthContext);

  const handleChangeModule = (mod) => {
    // console.log("mod", mod);
    // if (mod.type !== "autoHostSlide") {
    return axios({
      method: "patch",
      url: `${process.env.REACT_APP_EVENT_SERVICE}/current-module/${currentModule.id}`,
      // headers: {
      //   Authorization: `bearer ${loadAuthToken()}`,
      // },
      data: {
        moduleId: mod.id,
        moduleType: mod.type,
        moduleTitle: mod.title,
      },
    })
      .then(() => {
        if (mod.eventScript) {
          scriptsChannel.postMessage(mod.eventScript);
          localStorage.setItem("slideScript", mod.eventScript.script);
          localStorage.setItem("slideDuration", mod.eventScript.durationMin);
          const d = new Date();
          d.setTime(d.getTime() + 4 * 24 * 60 * 60 * 1000);
          // cookie.set("script", mod.eventScript, {
          //   expires: d,
          //   domain: process.env.REACT_APP_DOMAIN,
          //   path: "/",
          // });
        } else {
          scriptsChannel.postMessage([
            { script: "not Found", durationMin: 0, id: 0 },
          ]);
        }

        setCurrentSlide(mod);
      })
      .catch((err) => {
        if (
          err.error === "your token is not valid" ||
          err.error === "Your token is expired"
        ) {
          console.log("error", err);
          this.context.sendToLogin();
        }
      });
    // } else {
    //   setCurrentSlide(mod);
    // }
  };

  const handleEndSession = () => {
    return axios({
      method: "patch",
      url: `${process.env.REACT_APP_EVENT_SERVICE}/session/${sessionId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: {
        completed: true,
        started: false,
        completedAt: Date.now(),
      },
    })
      .then((res) => {
        console.log(res, "end session responce");
        // clear out cookies
        cookie.remove("accessToken", {
          domain: process.env.REACT_APP_DOMAIN,
          path: "/",
        });
        cookie.remove("user", {
          domain: process.env.REACT_APP_DOMAIN,
          path: "/",
        });
        cookie.remove("script", {
          domain: process.env.REACT_APP_DOMAIN,
          path: "/",
        });
        cookie.remove("slideIndex", {
          domain: process.env.REACT_APP_DOMAIN,
          path: "/",
        });
        cookie.remove("wopModuleId", {
          domain: process.env.REACT_APP_DOMAIN,
          path: "/",
        });
        cookie.remove("wordCloudModuleId", {
          domain: process.env.REACT_APP_DOMAIN,
          path: "/",
        });
        cookie.remove("pollModuleId", {
          domain: process.env.REACT_APP_DOMAIN,
          path: "/",
        });
        cookie.remove("czModuleId", {
          domain: process.env.REACT_APP_DOMAIN,
          path: "/",
        });
        cookie.remove("sessionId", {
          domain: process.env.REACT_APP_DOMAIN,
          path: "/",
        });
        const localStorageKeys = Object.keys(localStorage);
        localStorageKeys.forEach((lsKey) => {
          if (lsKey.includes("slide")) {
            localStorage.removeItem(lsKey);
          }
          if (lsKey.includes("wop")) {
            localStorage.removeItem(lsKey);
          }
          if (lsKey.includes("wordCloud")) {
            localStorage.removeItem(lsKey);
          }
          if (lsKey.includes("poll")) {
            localStorage.removeItem(lsKey);
          }
          if (lsKey.includes("wordPopcorn")) {
            localStorage.removeItem(lsKey);
          }
          if (lsKey.includes("cz")) {
            localStorage.removeItem(lsKey);
          }
          if (lsKey.includes("rightButtonText")) {
            localStorage.removeItem(lsKey);
          }
          if (lsKey.includes("sessionPin")) {
            localStorage.removeItem(lsKey);
          }
          if (lsKey.includes("videoPlaying")) {
            localStorage.removeItem(lsKey);
          }
        });
        window.location.replace("https://join.host/admin/sessions");
        // axios call to send email to user.email generic email:
        return axios({
          method: "post",
          url: `${process.env.REACT_APP_EVENT_SERVICE}/send-email-on-session-end`,
          headers: {
            Authorization: `bearer ${loadAuthToken()}`,
          },
          data: {
            user,
            sessionId,
            pin,
            audienceUsers,
            liveQuestions,
            flagshipModule,
          },
        }).catch((err) => alert(err));
      })
      .catch((err) => {
        alert(err);
      });
  };

  // const handleCloseDialog = () => {
  //   setIsDialogOpen(false);
  // };

  // variable to create expiration 48 hours later
  const d = new Date();
  d.setTime(d.getTime() + 2 * 24 * 60 * 60 * 1000);

  const next = () => {
    if (modules) {
      if (index < modules.length - 2) {
        const moduleIndexed = modules[index + 1];
        handleChangeModule(moduleIndexed);
        // change to next slide
        setIndex(index + 1);
        setCurrentSlide(moduleIndexed);
        cookie.set("slideIndex", index + 1, {
          expires: d,
          domain: process.env.REACT_APP_DOMAIN,
          path: "/",
        });

        //set module Id as a cookie, to be retrieved on reports slide and removed on end session
        if (moduleIndexed.type === "walkOfPrivilege") {
          // setWopModuleId(moduleIndexed.id);
          cookie.set("wopModuleId", moduleIndexed.id, {
            expires: d,
            domain: process.env.REACT_APP_DOMAIN,
            path: "/",
          });
          setFlagshipModule(moduleIndexed.type);
        }
        if (moduleIndexed.type === "wordCloud") {
          cookie.set("wordCloudModuleId", moduleIndexed.id, {
            expires: d,
            domain: process.env.REACT_APP_DOMAIN,
            path: "/",
          });
          setFlagshipModule(moduleIndexed.type);
        }
        if (moduleIndexed.type === "poll") {
          cookie.set("pollModuleId", moduleIndexed.id, {
            expires: d,
            domain: process.env.REACT_APP_DOMAIN,
            path: "/",
          });
        }
        if (moduleIndexed.type === "comfort") {
          cookie.set("czModuleId", moduleIndexed.id, {
            expires: d,
            domain: process.env.REACT_APP_DOMAIN,
            path: "/",
          });
        }
      }
      if (index === modules.length - 2) {
        // change to last slide and change button text from 'next' to 'End'
        handleChangeModule(modules[index + 1]);
        buttonChannel.postMessage("lastSlide");
        localStorage.setItem("rightButtonText", "End");
        setIndex(index + 1);
        setCurrentSlide(modules[index + 1]);
        cookie.set("slideIndex", index + 1, {
          expires: d,
          domain: process.env.REACT_APP_DOMAIN,
          path: "/",
        });
        setRightButtonText("End");
      }
      if (index === modules.length - 1) {
        buttonChannel.postMessage("open");
        setIsDialogOpen(!isDialogOpen);
      }
    }
  };

  const back = () => {
    if (modules.length) {
      if (index > 0) {
        handleChangeModule(modules[index - 1]);
        setCurrentSlide(modules[index - 1]);
        setIndex(index - 1);
        cookie.set("slideIndex", index - 1, {
          expires: d,
          domain: process.env.REACT_APP_DOMAIN,
          path: "/",
        });
        buttonChannel.postMessage("back");
        localStorage.setItem("rightButtonText", "Next");
      }
    }
  };

  const getLiveQuestions = (sessionId) => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_LIVE_CHAT_SERVICE}/questions?sessionId=${sessionId}
      `,
    }).then((res) => {
      setLiveQuestions(res.data.total);
    });
  };

  const findSession = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_EVENT_SERVICE}/host-session?userId=${user.id}`,
    })
      .then((res) => {
        setModules(res.data.modules);
        setPin(res.data.pin);
        setCurrentSlide(res.data.modules[index]);
        setSessionId(res.data.id);
        setAudienceUsers(res.data.audienceUsers);
        getLiveQuestions(res.data.id);
        setCurrentModule(res.data.currentModule);
        setIsDemo(res.data.event.demo);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (pin > 0) {
      localStorage.setItem("sessionPin", pin);
    }
  }, [pin]);

  useEffect(() => {
    if (sessionId !== 0) {
      setChannel(pusher.subscribe(`${sessionId}`));
    }
  }, [sessionId, pusher, user]);

  useEffect(() => {
    if (user && user.id !== 0) {
      findSession(user.id);
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user && user.id !== 0) {
      document.addEventListener("visibilitychange", findSession(user.id));

      window.addEventListener("focus", findSession(user.id));
      document.addEventListener("focus", findSession(user.id));

      window.addEventListener("resume", findSession(user.id));
      document.addEventListener("resume", findSession(user.id));
    }

    return () => {
      document.removeEventListener("visibilitychange", findSession);
      window.removeEventListener("focus", findSession);
      document.removeEventListener("focus", findSession);
      window.removeEventListener("resume", findSession);
      document.removeEventListener("resume", findSession);
    };
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // variable to create expiration 96 hours later
    const d = new Date();
    d.setTime(d.getTime() + 4 * 24 * 60 * 60 * 1000);

    let foundScript = "";
    foundScript = modules.length ? modules[index] : null;
    if (foundScript) {
      localStorage.setItem("slideScript", foundScript.eventScript.script);
      localStorage.setItem(
        "slideDuration",
        foundScript.eventScript.durationMin
      );
    }
  }, [modules, currentModule]);

  useEffect(() => {
    if (modules.length) {
      if (index === 0) {
        handleChangeModule(modules[index]);
      }
    }
  }, []);

  useEffect(() => {
    controlsChannel.onmessage = (ev) => {
      console.log(ev.data, "data from control channel");
      switch (ev.data) {
        case "next":
          next();
          break;
        case "back":
          back();
          break;
        case "endSession":
          // console.log("END CLICKED");
          handleEndSession();
          break;
        default:
          return "null";
      }
    };
    //if i try to close the channel it breaks. think the dependencies break it.
    // return () => {
    //   controlsChannel.close();
    // };
  }, [index, modules]);

  return (
    <>
      <Stats
        audienceUsers={audienceUsers}
        liveQuestions={liveQuestions}
        channel={channel}
        moduleId={currentSlide.moduleId}
      />

      <Modules
        channel={channel}
        currentSlide={currentSlide}
        moduleType={currentSlide.type}
        moduleId={currentSlide.moduleId}
        moduleTitle={currentSlide.title}
        currentModuleId={currentSlide.id}
        pin={pin}
        sessionId={sessionId}
        modules={modules}
        isDemo={isDemo}
      />
      <Pin pin={pin} />
    </>
  );
};

export default Host;
