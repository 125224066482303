import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Timer from "../Timer/Timer";

const useStyles = makeStyles({
  root: {
    width: "100%",
    alignItems: "center",
    display: "flex",
  },
});

function toHoursAndMinutes(totalMinutes) {
  // const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes % 60);
  let seconds = Number((totalMinutes + "").split(".")[1])
    ? Number((totalMinutes + "").split(".")[1])
    : 0;
  seconds = seconds > 10 ? seconds : `0${seconds}`;

  return (
    <div>
      {/* <span>{hours > 0 ? `${hours}:` : null}</span> */}
      <span>{minutes >= 10 ? minutes : `0${minutes}`}</span>:
      <span>{seconds}</span>
    </div>
  );
}

export default function SimpleBottomNavigation(props) {
  const { durationMin } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <p>TIME LEFT: </p>
      <Timer />
      <p>/</p>
      <div>{toHoursAndMinutes(durationMin)}</div>
    </div>
  );
}
