import React from "react";
import { makeStyles } from '@material-ui/core/styles';

import SlideNav from '../../../components/NavButtons/SlideNavButtons'



const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    flexGrow: 1,
    // padding: 10
  },
}));

export default function CenteredGrid(props) {
  const classes = useStyles();




  return (
    <div className={classes.root}>

        <SlideNav />

    </div>
  );
}
