import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";

import Script from "./Script/Script";
import Notes from "./Notes/Notes";
import Chat from "./Chat/Chat";

const AntTabs = withStyles({
  root: {},
  indicator: {
    backgroundColor: "#5C1783",
    height: "5px",
  },
})(Tabs);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`navpanel-${index}`}
      aria-labelledby={`nav-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-${index}`,
    "aria-controls": `navpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "6vh",
    flexGrow: 1,
  },

  pannel: {
    height: "72vh",
    overflowY: "scroll",
    overflowX: "hidden",
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [messageCount, setMessageCount] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AntTabs
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        aria-label="nav"
      >
        <Tab label="Your Script" {...a11yProps(0)} />
        <Tab label="Notes" {...a11yProps(1)} />

        <Tab
          label={
            <Badge badgeContent={messageCount} color="primary">
              <div>Live Chat</div>
            </Badge>
          }
          {...a11yProps(2)}
        />
      </AntTabs>
      <TabPanel className={classes.pannel} value={value} index={0}>
        <Script />
      </TabPanel>
      <TabPanel className={classes.pannel} value={value} index={1}>
        <Notes />
      </TabPanel>
      <TabPanel className={classes.pannel} value={value} index={2}>
        <Chat setMessageCount={setMessageCount} />
      </TabPanel>
    </div>
  );
}
