/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import _ from "lodash";
import IconButton from "@material-ui/core/IconButton";
import TagCloud from "react-tag-cloud";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { PulseLoader } from "react-spinners";
import Button from "@material-ui/core/Button";

import { stereoTypeWords, authenticWords, words } from "./SampleWords";

const useStyles = makeStyles({
  tagCloud: {
    height: "50vh",
    width: "100vw",
    flex: "1",
  },
  image: {
    height: "50px",
    // display: "grid",
    // justifyItems: "center",
    // justifyContent: "center",
  },
  sup: {
    fontSize: "20px",
    color: "#666666",
  },
  navResults: {
    display: "flex",
    justifyContent: "center",
  },
  header: {
    marging: "60px",
    display: "flex",
    justifyContent: "center",
    fontSize: "xxx-large",
    color: "#2a7050",
  },
  wordCount: {
    display: "grid",
    alignItems: "center",
    color: "#b7b7b7",
  },
  // wordDiv: {
  //   fontFamily: "Montserrat",
  //   fontSize: 30,
  //   fontStyle: "italic",
  //   color: "#666666",
  // },
  buttonDiv: {
    display: "flex",
    justifyContent: "center",
    color: "#b7b7b7",
    paddingBottom: "20px",
  },
  button: {
    color: "#b7b7b7",
  },
});

const WordCloudScreen = ({ sessionData, isDemo }) => {
  // console.log("sessionData", sessionData, "isDemo", isDemo);
  const [answers, setAnswers] = useState([]);
  const [answersOnDisplay, setAnswersOnDisplay] = useState([]);
  const [currentPaginationIndex, setCurrentPaginationIndex] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [splitAnswers, setSplitAnswers] = useState([]);
  const [isLoading] = useState(null);
  const [question, setQuestion] = useState(0);
  // const [buttonText, setButtonText] = useState("Next");
  const paginationPoint = 15;

  // console.log(answers, "answers", question);

  const paginateLeft = () => {
    if (currentPaginationIndex !== 0) {
      setAnswersOnDisplay(splitAnswers[currentPaginationIndex - 1]);
      setCurrentPaginationIndex(currentPaginationIndex - 1);
    }
  };

  const paginateRight = () => {
    if (currentPaginationIndex < splitAnswers.length - 1) {
      setAnswersOnDisplay(splitAnswers[currentPaginationIndex + 1]);
      setCurrentPaginationIndex(currentPaginationIndex + 1);
    }
  };

  const setWords = (props) => {
    const totalAnswers = [...Object.entries(props)];
    // console.log("totalAnswers", totalAnswers);
    setAnswers([...totalAnswers]);

    setAnswersOnDisplay([...totalAnswers].splice(0, paginationPoint));
    setSplitAnswers(_.chunk(totalAnswers, paginationPoint));
  };

  useEffect(() => {
    if (isDemo) {
      if (question.id === 1) {
        setWords(stereoTypeWords);
      } else if (question.id === 2) {
        setWords(authenticWords);
      } else {
        setWords(words);
      }
    } else {
      setWords(sessionData[questionIndex].data);
    }
    setQuestion(sessionData[questionIndex].question.word_cloud_question);
  }, [question, questionIndex]);

  const nextQuestion = () => {
    if (sessionData.length > questionIndex + 1) {
      setQuestionIndex(questionIndex + 1);
      setCurrentPaginationIndex(0);
    } else {
      setQuestionIndex(0);
      setCurrentPaginationIndex(0);
    }
  };
  // useEffect(() => {
  //   if (sessionData.length > questionIndex + 1) {
  //     setButtonText(
  //       `Show ${
  //         sessionData[questionIndex + 1].question.word_cloud_question
  //           .description
  //       }`
  //     );
  //   } else {
  //     setButtonText(
  //       `Show ${sessionData[0].question.word_cloud_question.description}`
  //     );
  //   }
  // }, [questionIndex]);

  const classes = useStyles();
  return (
    <div>
      {/* <img src={question.icon} className={classes.image} alt="StereoTypeMask" /> */}
      <h3 className={classes.header}>{question.description}</h3>
      {isLoading ? (
        <PulseLoader
          sizeUnit="px"
          size={15}
          color="#6CCA98"
          loading
          speedMultiplier={".5"}
        />
      ) : (
        <div>
          <TagCloud
            className={classes.tagCloud}
            style={{
              // fontSize: 30,
              padding: 15,
            }}
          >
            {answersOnDisplay.map((answer, index) => (
              <div
                key={index + 20203}
                // className={classes.wordDiv}
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 30,
                  fontStyle: "italic",
                  color: "#666666",
                }}
              >
                <p>
                  {answer[1].word}{" "}
                  <sup className={classes.sup}>({answer[1].count})</sup>
                </p>
              </div>
            ))}
          </TagCloud>
          <div className={classes.navResults}>
            <IconButton
              onClick={() => paginateLeft()}
              disabled={!(currentPaginationIndex !== 0)}
            >
              <KeyboardArrowLeftIcon fontSize="small" />
            </IconButton>
            <span className={classes.wordCount}>
              {`${currentPaginationIndex * paginationPoint + 1} - ${
                answersOnDisplay.length +
                currentPaginationIndex * paginationPoint
              } of ${answers.length}`}
            </span>
            <IconButton
              onClick={() => paginateRight()}
              disabled={!(currentPaginationIndex < splitAnswers.length - 1)}
            >
              <KeyboardArrowRightIcon fontSize="small" />
            </IconButton>
          </div>
        </div>
      )}
      <div className={classes.buttonDiv}>
        <Button
          width="100%"
          className={classes.button}
          onClick={() => nextQuestion()}
        >
          ( Flip words )
        </Button>
      </div>
    </div>
  );
};

WordCloudScreen.propTypes = {
  sessionData: PropTypes.array.isRequired,
  isDemo: PropTypes.bool,
};

export default WordCloudScreen;
